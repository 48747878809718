import { ProtectedComponent } from '@/components/protected/protected.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, RolesGuard } from '@shared/_guards';



const appRoutes: Routes = [
    {
        path: '',
        component: ProtectedComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: () => import('@/modules/dashboard/pages/dashboard-layout/dashboard-layout.module').then(x=> x.DashboardLayoutModule)

            },
            {
                path: 'heatmap',
                loadChildren: () => import('@/modules/heatmap/pages/heatmap-list/heatmap-list.module').then(x=> x.HeatmapListModule),
            },
            {
                path: 'scheduling',
                loadChildren: () => import('@/modules/scheduling/pages/building-schedule/building-schedule.module').then(x=> x.BuildingScheduleModule),
            },
            {
                path: 'systemoperations',
                loadChildren: () => import('@/modules/system-operations/pages/system-operations-layout/system-operations-layout.module').then(x=> x.SystemOperationsLayoutModule)

            },
            {
                path: 'zones',
                loadChildren: () => import('@/modules/zones/pages/zones-list/zones-list.module').then(x=> x.ZonesListModule)
            },

            {
                path: 'settings/floorplan',
                loadChildren: () => import('@/modules/settings/pages/settings-floor-plan/settings-floor-plan.module').then(x=> x.SettingsFloorPlanModule),
                canActivate: [RolesGuard],
                data: {roles:['L1','L2', 'L3'] }

            },
            {
                path: 'settings/tuners',
                loadChildren: () => import('@/modules/settings/pages/tuners/tuners.module').then(x=> x.TunersModule),
                canActivate: [RolesGuard],
                data: {roles:['L1','L2', 'L3'] }

            },
            {
                path: 'settings/buildingOptions',
                loadChildren: () => import('@/modules/settings/pages/building-options/building-options.module').then(x=> x.BuildingOptionsModule),
                canActivate: [RolesGuard],
                data: {roles:['L2', 'L3'] }
            },
            {
                path: 'settings/remote-access',
                loadChildren: () => import('@/modules/settings/pages/remote-access/remote-access-layout.module').then(x=> x.RemoteAccessLayoutModule),
                canActivate: [RolesGuard],
                data: {roles:['L1','L2', 'L3'] }

            },
            {
                path: 'user-management',
                loadChildren: () => import('@/modules/user-management/pages/user-management-layout/user-management-layout.module').then(x=> x.UserManagementLayoutModule),
                canActivate: [RolesGuard]
            },
            {
                path: 'account',
                loadChildren: () => import('@/modules/account-management/account-management.module').then(x=> x.AccountManagementModule)
            },

            {
                path: 'portfolio-analytics-management',
                loadChildren: () => import('@/modules/energy/pages/energy-dashboard/energy-dashboard.module').then(x=> x.EnergyDashboardModule)
            },
            {
                path: 'energy-dashboard',
                loadChildren: () => import('@/modules/energy/pages/energy-dashboard/energy-dashboard.module').then(x=> x.EnergyDashboardModule)
            },

            // {
            //     path: 'energymanagement',
            //     loadChildren: '@/modules/energy/pages/energy-analysis/energy-analysis.module#EnergyAnalysisModule'
            // },
            {
                path: 'alerts',
                loadChildren: () => import('@/modules/alerts/pages/alerts-list/alerts-list.module').then(x=> x.AlertsListModule)
            },
            {
                path: 'site-explorer',
                loadChildren: () => import('@/modules/site-explorer/pages/site-explorer.module').then(x=> x.SiteExplorerModule)
            },
            {
                path: 'named-schedule',
                loadChildren: () => import('@/modules/named-schedules/pages/named-schedule/named-schedule-layout.module').then(x=> x.NamedScheduleLayoutModule)
            },
            {
                path: 'cpm',
                loadChildren: () => import('@/modules/cpm/pages/chiller-plant-monitoring/chiller-plant-monitoring.module').then(x => x.ChillerPlantMonitoringModule)
            },
            {
                path: 'alert-config',
                loadChildren: () => import('@/modules/alerts-config/alerts-config.module').then(x => x.AlertsConfigLayoutModule),
                canActivate: [RolesGuard],
                data: {roles:['L2', 'L3'] }
            },
            {
                path: 'audit-trail',
                loadChildren: () => import('@/modules/audit-trail/pages/audit-trail.module').then(x => x.AuditTrailFacilisightModule),
                canActivate: [RolesGuard],
                data: {roles:['L2','L3'] } 
            },
            {
                path: 'site-manager',
                loadChildren: () => import('@/modules/site-manager/site-manager.module').then(x => x.SiteManagerModule),
                canActivate: [RolesGuard],
                data: {roles:['L3'] }
            },
            {
                path: 'energy-configuration',
                loadChildren: () => import('@/modules/energy-configuration/pages/energy-configuration-mangement/energy-configuration-mangement.module').then(x => x.EnergyConfigurationMangementModule),
                canActivate: [RolesGuard],
                data: {roles:['L2','L3'] }
            },
            {
                path: 'site-sequencer',
                loadChildren: () => import('@/modules/site-sequencer/pages/site-sequencer.module').then(x => x.SiteSequencerModule)
            },
            {
                path: '**',
                redirectTo: '/dashboard'
            }


        ],
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always'

    },

    {
        path: '**',
        redirectTo: '/dashboard'
    }

];

export const routing = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' });
