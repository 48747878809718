<div class="fs-heatmap-wrapper">
  <div [ngClass]="(siteNameModalStyleClickedFlag || modalStyleClickedFlag) ? 'isModalClicked' : 'isModalHide'"
    (click)="ClickedOut($event)"></div>

  <div class="fs-heatmap-wrapper__header">
    <fs-heatmap-header [floors]="buildings.floorsMaster" [ccus]="buildings.floorCcu" [deadbandScale]="deadbandScale" [siteId]="refs.siteRef"
      [zones]="buildings.zones" [highlightCCU]="highlightCCU" 
      [checkForEventType]="checkForEventType"
      (onChangeFloor)="loadFloorInfo($event)" 
      (onChangeCCU)="changeCCU($event)"
      (onChangeZone)="changeZone($event)" 
      (onSeachInputChange)="searchChange($event)" 
      [ccusWithPairedZones]=ccusWithPairedZones
      (onHoverCcu)="callSystemSettingsAfterDelay($event)" 
      (onClickCcu)="getSystemSettingsOnClick($event)"
      (onLeaveCcu)="clearHoverTimer();unHighLighZones($event);" 
      (autoRefreshChange)="autoRefreshChange($event)" 
      (ccuOrderChange)="userPrefChange($event)"
      (filterChange)="filterChangeHandle($event)"
      (defaultFilterChange)="defaultFilterChangeHandle($event)"
      [defaultFilter] = "defaultFilter"
      [selectedFilter]="selectedFilter"
      [hideCCUHeader]="hideCCUHeader"
      (scrollAndExpandEvent)="scrollNExpandNotes($event)"
      [showQRCode]="showQRCode">
    </fs-heatmap-header>
  </div>

  <div class="fs-heatmap-wrapper__content" *ngIf="skeletonContentLoaded" 
    [ngClass]="{'shrink-ctnr':(searchText && searchMatchingZones.length&&!selectedZone),'scale-ctnr':!(searchText && searchMatchingZones.length&&!selectedZone)}" #heatmapView>

    <ul class="fs-heatmap-wrapper__content__parentFloorPlanContainer p-b-20">
      <div *ngFor="let floor of lazyLoadFloors;trackBy:floorsId"
        class="floor">
        <p class="notranslate">{{ floor.name }}</p>
        
        <div class="fs-heatmap-wrapper__content__parentFloorPlanContainer__childFloorPlanContainer"
            [ngStyle]="{ 'height': '80vh' }"
            id="{{floor.id}}"
            [ngClass]="{'floorPlanContainerWidth' : lazyLoadFloors.length > 1 , 'singleFloorCtnr' : lazyLoadFloors.length<2 }">
              <puc-planner-layout *ngIf="selectedFilter" [showSchedulerOccupancy] = "showSchedulerOccupancy" inputFromHeatmap ="true" [selectedFloorRef]="floor._id" [selectedFilter] = "selectedFilter" [selectedFloorId]=floor._id [siteRef]=refs.siteRef [incomingFloorData]="floor" (zoneHoverOrClick)=addClickEvent($event) [selectedCCu]="selectedCCu" [selectedZone]="selectedZone" [autoRefreshSelection]="autoRefreshSelection" [buildings]="buildings"   [multiFloorSelected] = "multiFloorSelected"></puc-planner-layout>
              <div *ngIf='floor.floorPlanErrorDisplay'>{{floor.errorText}}</div>
        </div>
      </div>
      <div class="" [class]="singleFloor ? '': 'hidden'"></div>
    </ul>
    <div class="p-l-20 p-b-30" *ngIf="!buildings.floors.length">No floors available</div>
  </div>

    <div class="fs-heatmap-wrapper__content" *ngIf="!skeletonContentLoaded || showLazyLoader">
      <div class="row">
        <div class="column" *ngFor="let repeat of [0,1]">
          <ngx-skeleton-loader [theme]="{ 'border-radius': '0', height: '600px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  
  <div class="fs-heatmap-wrapper__accordion" *ngIf="buildings.floors.length > 0">
  </div>

  <!-- Chat Agent -->
  <app-chat *ngIf="chatSessionActive" (close)="chatSessionActive = false" [siteId]="refs.siteRef" [buildingEntityList]="buildings?.entites"></app-chat>

  <div *ngIf="chatEnabled" class="chat-global-container">
    <button (click)="onToggleChat()" tabindex="-1" mat-fab color="primary" #tooltip="matTooltip" class="m-b-10" [matTooltip]="chatSessionActive ? 'Close Chat' : 'Open Chat'" [matTooltipPosition]="'left'">
      <i *ngIf="!chatSessionActive" class="fas fa-comment-alt" aria-hidden="true"></i>
      <i *ngIf="chatSessionActive" class="fas fa-times" aria-hidden="true"></i>
    </button>
  </div>
  
  <span *ngIf="!isFilter && checkCertificationAccess('customGlobalWidgets')">
    <div class="heatmap-custom-global-container" *ngIf="refs.siteRef">
      <!-- <button tabindex="-1" mat-fab color="primary" #tooltip="matTooltip" class="m-b-10 edit-fab" matTooltip="Edit Custom Visualization" *ngIf="showEditGlobalViz"
        (click)="onAddUpdateAnalytics('edit', 'global')" [matTooltipPosition]="'left'" id="editCustomVizButton">
        <i class="fas fa-pencil-alt" aria-hidden="true"></i>
      </button> -->
      <button tabindex="-1" class="pointSummary" mat-mini-fab color="primary" *ngIf="openMenu" (click)="openPointSummaryList()" #tooltip="matTooltip" matTooltip="Point Summary" [matTooltipPosition]="'left'" 
      id="addPointSummaryGlobalButton"><i class="fas fa-list-ul" aria-hidden="true"></i>
      </button>
      <!-- <button tabindex="-1" class="analytics" mat-mini-fab color="primary" *ngIf="showAddButton && openMenu" (click)="onAddUpdateAnalytics('create', 'global')" #tooltip="matTooltip" matTooltip="Add analytics" [matTooltipPosition]="'left'" 
      id="addCustomVizButton"><i class="fas fa-chart-line" aria-hidden="true"></i>
      </button> -->
      <button tabindex="-1" class="mat-elevation-z8 new-analytics" mat-mini-fab color="primary" *ngIf="openMenu" (click)="onAddNewAnalytics()" #tooltip="matTooltip" matTooltip="Analytics" [matTooltipPosition]="'left'" 
      id="addAnalyticsGlobalButton"><i class="fas fa-chart-line" aria-hidden="true"></i>
      </button>
      <button tabindex="-1" mat-fab color="primary" (click)="clickMenu()" class="m-b-10">
        <i class="fas fa-plus" *ngIf="!openMenu" aria-hidden="true"></i>
        <i class="fas fa-times" *ngIf="openMenu" aria-hidden="true"></i>
      </button>
    </div>
  </span>

  <div *ngIf="showSummaryTables" class="m-l-20 p-r-50">
    <puc-summary-table-list [autoAddedSummaryTables]="autoAddedSummaryTables" [fromPartner]="isPartner" [ngClass]="{'hide-Summary-table-section': hideSummaryTableSection}"  [siteRef]="refs.siteRef" [isShowSummaryTable]="isSummaryTableCheckboxDisabled" [isReadOnly]="isValidCertificationLevel" [siteName]="siteName" [buildingEntities]="buildings?.entites" (summaryTableOpened) = 'summaryTableOpened($event)' [autoRefreshSubject]="autoRefreshSubject" [selectedZoneList] = "selectedZone"></puc-summary-table-list>
  </div>

  <!-- AI Dashboard -->
  <div *ngIf="showAIDashboard && chatSessionActive" class="m-l-20 p-r-50">
    <puc-ai-dashboard [siteId]="refs.siteRef" [siteName]="siteName" [buildingEntityList]="buildings?.entites" [dashboardData]="aiDashboardData" #aiDashboardView></puc-ai-dashboard>
  </div>

  <div *ngIf="checkCertificationAccess('notes') && refs.siteRef && showNotesAccordion" class="m-l-20 p-r-50 notes-section">
    <puc-heatmap-notes-layout [showFileUpload]= "fileUpload" [expandNotesSection]= "expandNotesSection" [siteRef]="refs.siteRef" [buildings]="buildings"  (notedUploadOpened) = 'notedUploadOpened($event)' [fromInternal]="false" [ccusWithPairedZones]=ccusWithPairedZones></puc-heatmap-notes-layout>
  </div>
</div>


<puc-modal *ngIf="ccuOrZone.type =='zone'"class="forzonepopup" footerActive="0" [title]=""
  [ngStyle]="modalStyleClickedFlag ? modelStyleOnClicked : modelStyle" (mouseleave)="onPopEvent($event)"
  (mouseenter)="onPopEvent($event)" id="{{zoneId}}" [ngClass]="{'forzonepopup-hover': (ccuOrZone.type == 'zone' && ccuOrZone.actionUsed == 'hover')}">
  <div class="modal-header">
    <ul class="p-b-10">
      <li class="entity-name" [class.w-100]="!modalStyleClickedFlag">
        <h3 class="text-truncate"><span matTooltip="{{ activeTile }}" class="modal-title notranslate" matTooltipPosition="below">{{ activeTile }}</span></h3>
      </li>
      <ng-container *ngIf="zoneSettings">
        <li *ngIf="zoneSettings.equipScheduleStatus;else filler"
          [innerHTML]="isDataLoaded(zoneSettings.equipScheduleStatus.val, true) | pucSafeHtml"></li>
        <ng-template #filler>
          <li></li>
        </ng-template>
      </ng-container>
      <li class="date-picker-li" *ngIf="(ccuOrZone.actionUsed !== 'hover')">
        <span [ngStyle]="{'font-size': '9pt'}">
          Performance for
        </span>
        <span class="notranslate">
          <input type="text" id="zone-graph-datepicker" class="date-picker" readonly ngxDaterangepickerMd
            [linkedCalendars]="true" [drops]="'down'" [opens]="'left'"
            [locale]="{ applyLabel: 'ok', format: 'DD MMM YY', separator: ' to '}" [(ngModel)]="selected"
            (change)="setDateRange($event)" [alwaysShowCalendars]="true" [ranges]="ranges" [autoApply]="true"
            [showClearButton]="true" [showRangeLabelOnInput]="false" [closeOnAutoApply]="true"
            [class.disabled-download]="!(checkWidgetDataLoaded())" />
        </span>
      </li>
      <li class="download" *ngIf="modalStyleClickedFlag" [class.disabled-download]="!(checkWidgetDataLoaded() && enableDownLoad) ">
        <span *ngIf="!isDownloadExcelPending"(click)="exportExcel('Zone')" class="fas fa-download download-icon" id="exportZoneData" aria-hidden="true"></span>
        <span *ngIf="isDownloadExcelPending" class="fas fa-spinner fa-spin spin-icon" id="exportZoneData" aria-hidden="true"></span>
        <span class="downloading-text" *ngIf="isDownloadExcelPending">Downloading...</span>
      </li>

    </ul>
    <div *ngIf="hoverZoneData && !modalStyleClickedFlag " class="flex">
      <div class="flex temp-values" *ngIf="hoverZoneData.hasOwnProperty('currentTemp') && hoverZoneData.currentTemp !== ''">
        <div>{{hoverZoneData.currentTemp}}</div>
        <div *ngIf="hoverZoneData?.singleDual == 0 || hoverZoneData?.singleDual == 2  || !hoverZoneData?.singleDual"> | </div>
        <div *ngIf="hoverZoneData?.singleDual == 0 || hoverZoneData?.singleDual == 2  || !hoverZoneData?.singleDual">{{hoverZoneData.desiredTempHeating}}</div>
        <div *ngIf="hoverZoneData?.singleDual == 0 || hoverZoneData?.singleDual == 1  || !hoverZoneData?.singleDual"> | </div>
        <div *ngIf="hoverZoneData?.singleDual == 0 || hoverZoneData?.singleDual == 1  || !hoverZoneData?.singleDual">{{hoverZoneData.desiredTempCooling}}</div>
      </div>
      <div class="d-flex modules-colors" *ngIf="profiles && profiles.length > 1">
        <div class="d-flex flex-wrap p-l-20 justify-content-start">
          <ng-container *ngFor="let profile of profiles;trackBy:profileByID">
            <div class="flex p-5 p-r-10 p-t-10 align-items-center" *ngIf="hoverZoneData['profiles'] && hoverZoneData['profiles'][profile.profile._id] && hoverZoneData['profiles'][profile.profile._id].length">
                <div class="selected-color-box p-r-5" [ngStyle]="{'background-color' : condition?.color }" *ngFor="let condition of hoverZoneData['profiles'][profile.profile._id];"></div>
                <div class="p-r-10 notranslate">{{formatZoneName(profile)}}</div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="d-flex fw-version-container" *ngIf="hoverZoneData['modulesStatus']">
        <div class="d-flex flex-wrap p-l-5 justify-content-end">
          <ng-container *ngFor="let module of hoverZoneData['modulesStatus'];let islast = last">
            <div class="flex p-5 p-r-10 align-items-center">
                <div class="p-r-10">{{module['profile'] + " " +module['fwVersion'] }}</div>
                <div class="p-r-10" *ngIf="!islast"> | </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <puc-graph-xaxis *ngIf="xCords.length" [xCords]="xCords"></puc-graph-xaxis>
  </div>
  <div *ngIf="refs.roomRef">
    <ng-container *ngFor="let profile of profiles;trackBy:profileByID">
      <puc-accordion [blockTitle]="formatZoneName(profile)"  [disableTranslation]="true" [profileId]="profile?.profile?._id"  [showActive]="(!profile.isChild || !isModbus)" [isEquipGraphics]="profile['showEquipGraphics']" [isActive]="isModuleOnline(profile)" (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','accordionSectionSelction'])" 
      (displayTimeToggle)="updateTimeToggle($event)" [isOpen]="terminalEquipGraphics" [enableLastUpdatedTime]="showlastUpdatedToggleEnabled" [enableViewAssets]="profile['enableViewAssets']" [taggedFilesList]="profile['taggedFiles']" [siteRef]="refs.siteRef" [showAssetButton]="!profile.isChild && fileUpload"  [popupType]="ccuOrZone.actionUsed">
        <ng-container *ngIf="dispTerminalGraphic && showTerminalEquipGraphics && profile['showEquipGraphics']">
          <span class="label">Equip Graphics : </span>      
            <puc-terminal-equip-graphics class= "equip-graphic-container"  [profile]="profile" [multiModuleProfile]="multiModuleProfile" [equipObj]="getEquip(['system', 'equip', 'profile'])"
                [userTempPreference]="userTempPreference" [showLastUpdated]="showlastUpdatedToggleEnabled" [zoneSettings]="equipGraphicData"
                [monitoringEquipParameteres]="monitoringEquipParameteres" [zoneHovercheckFlag]="zoneHovercheckFlag"
                [checkHyperStatProfile]="checkHyperStatProfile" [isDomainIntegrated]="isDomainIntegrated">
              </puc-terminal-equip-graphics>
              <puc-asset-data-table [zoneSettings]="equipGraphicData" [zoneHovercheckFlag]="zoneHovercheckFlag" [profile]="profile"
                [isZone]="true" >
              </puc-asset-data-table>
          </ng-container>

            <ng-container *ngIf="!profile['showEquipGraphics']">
              <puc-asset-data-table [noSystemEquipGraphic]="true" [profile]="profile" [isZone]="true">
              </puc-asset-data-table>
            </ng-container>

        <puc-accordion *ngIf="profile"  (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','preDefinedWidgets'])"
          [blockTitle]="'Predefined Widgets'" [isOpen]="zonePreDefinedWidgetsOpenState" [isActive]="isModuleOnline(profile)">
          <ng-container *ngFor="let widget of profile.widgets">
            <puc-accordion [blockTitle]="widget.name" [graphId]="widget.id + profile.profile._id"
              [titleStyle]="accordionHeaderStyle.secondLevel" isOpen="true">
              <puc-widget>
                <puc-graph [graphWidth]="graphWidth" [graphData]="widget" [graphId]="widget.id + profile.profile._id"
                  [linearData]="" (xAxisCords)="setXAxisCords($event)" [leftPos]="modal.room.left"
                  [moduleIdentifier]="profile.portNum" [moduleRef]="profile.profile._id">
                </puc-graph>
              </puc-widget>
            </puc-accordion>
          </ng-container>
          <ng-container *ngIf="profile.widgets && !profile.widgets.length">
            <div class="no-widgets">No widgets available</div>
          </ng-container>
        </puc-accordion>

        <puc-accordion *ngIf="profile && ccuOrZone.actionUsed == 'click'" (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','alertsAccordion'])" [blockTitle]="'Alerts'" [isOpen]="zoneAlertsAccordionOpenState" [titleStyle]="accordionHeaderStyle.firstLevel">
          <ng-container>
            <div *ngIf="refs.siteRef && zoneAlertsAccordionOpenState" class="table-container d-flex flex-column">
              <puc-alerts [siteRefs]="[refs.siteRef]" [equipId]="[profile?.profile?._id]" [showAlertType]="true" [equipGraphicsAlertAccordion]="true" [allAlerts]="true" [autoRefreshSubject]="autoRefreshSubject" [allSiteSelected]="false"></puc-alerts>
           </div>
          </ng-container>
        </puc-accordion>
      </puc-accordion>

    </ng-container>
    <ng-container *ngIf="ccuOrZone.type === 'zone'">
      <ng-container *ngIf="!checkCertificationAccess('customWidgets') && !checkCertificationAccess('customGlobalWidgets')">
        <puc-custom-widgets-view [isFacilisight]=true [customWidgets]=customWidgets [scope]=ccuOrZone?.type
          [actionUsed]=ccuOrZone?.actionUsed (accordionState)='setUserPreferencesDataForCustomWidget($event)'
          [customWidget]="zoneCustomWidgetsOpenState" [customGlobalWidget]="zoneCustomGlobalWidgetsOpenState"
          [heatmapConfigId]=globalDashboardObject?.id [customDateRange]=customDateRange
          (dataRecievedEvt)="chartDataReceivedView($event)"
          (accordionHiddenEvent)="accordionHiddenEvt($event)"></puc-custom-widgets-view>
      </ng-container>
    
      <ng-container *ngIf="checkCertificationAccess('customWidgets');">
        <puc-custom-widgets (onVisBuilderClosed)="onVisBuilderClosed();" (chartDataReceived)="chartDataReceived($event)"
          (onAddScopedAnalytics)="onAddScopedAnalytics($event)" [taglist] = tagList [scopedData]="scopedData"
          [showInlineVisBuilder]="showInlineVisBuilder" [editAnalytics]=editAnalytics 
          (updatePosition)="updatePosition($event)" 
          (setUserPreferenceData)="setUserPreferencesData($event,['zoneHeatmapPreferences','customWidgets'])" 
          [blockTitle]="'Custom Widgets'" 
          [openState]="zoneCustomWidgetsOpenState" 
          [customWidgets]="customWidgets.local" [serverDeleteInProgress]="serverDeleteInProgress" 
          [selectedWidgetForDeletionWidgetId]="selectedWidgetForDeletion?.widgetId" 
          [widgetDashboardObjectId]="globalDashboardObject?.id"
          [customDateRange] = "customDateRange"
          [selectedScopedEntityIndex]="selectedScopedEntityIndex"
          [customWidgetAction] ="'create'"
          [ccuOrZone] = "'zone'"
          [makeLocalWidgetsDataCall]="makeLocalWidgetsDataCall"
          (editWidgetEvt)="editWidgetEvt($event);"
          (deleteWidget)="deleteWidget($event);">
        </puc-custom-widgets>
      </ng-container>
      <ng-container *ngIf="checkCertificationAccess('customGlobalWidgets');">
        <puc-custom-global-widgets [customDateRange]=customDateRange [actionUsed]="ccuOrZone?.actionUsed"
          (setUserPreferenceData)="setUserPreferencesData($event,['zoneHeatmapPreferences','customGlobalWidgets'])"
          [widgetDashboardObjectId]="globalDashboardObject?.id" [customWidgets]="customWidgets?.global"
          [hiddenAccordions]="hiddenAccordions" [blockTitle]="'Custom Global Widgets'"
          [openState]="zoneCustomGlobalWidgetsOpenState" [accordionHeaderStyle]="accordionHeaderStyle?.firstLevel"
          [editAnalytics]=editAnalytics (chartDataReceived)="chartDataReceived($event)"
          (accordionHiddenEvt)="accordionHiddenEvt($event)"></puc-custom-global-widgets>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="ccuOrZone.type == 'zone'">
      <puc-analytics-layout [entitiesList]="entitiesList" [graphWidth]="graphWidth" [open]="zoneAnalyticsOpenState" (accordionStateChange)="setUserPreferencesData($event,['zoneHeatmapPreferences','analytics'])" [buildingObj]="buildings" [siteRef]="refs.siteRef" [analyticsFrom]="ccuOrZone.type" [siteName]="siteName" [ccuRef]="refs.ccuRef" [roomRef]="refs.roomRef" [fromPartner]="isPartner" [isReadOnly]="isValidCertificationLevel"></puc-analytics-layout>
    </ng-container>
    
    <ng-container *ngIf="loadSummaryView && ccuOrZone.actionUsed == 'click' && (ccuOrZone.type == 'zone')">
      <puc-point-summary-layout [open]="zonePointSummaryOpenState" (accordionStateChange)="setUserPreferencesData($event,['zoneHeatmapPreferences','pointSummary'])" [buildingObj]="buildings" [siteRef]="refs.siteRef" [ccuOrZone]="ccuOrZone.type" [siteName]="siteName" [ccuRef]="refs.ccuRef" [roomRef]="refs.roomRef" [fromPartner]="isPartner" [isReadOnly]="isValidCertificationLevel"></puc-point-summary-layout>
    </ng-container>
    <ng-container *ngIf="isValidProfile(profiles)">
    <puc-accordion blockTitle="Zone Tuners" class="system-tuners-accordian" [titleStyle]="accordionHeaderStyle.firstLevel"
      (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','zoneTuners'])"
      [isOpen]="zoneTunersOpenState"
      *ngIf="(ccuOrZone.type == 'zone') && (ccuOrZone.actionUsed == 'click')  && !isModbusProfile(profiles[profiles.length-1]) && checkCertificationAccess('tunersOta')">
      <ng-container *ngIf="!isTIZone">
        <puc-command class="m-12-a" [siteInfo]="this.refs" [level]="'zone'"></puc-command>
      </ng-container>
      <puc-tuner-list  [notesList] = "filteredNotesList" [hasGroup]="true" [level]="'zone'" (isTunerUpdated)="onTunerChange($event)" [siteInfo]="refs"
        [data]="zoneTuners"></puc-tuner-list>
    </puc-accordion>

    <puc-accordion blockTitle="Zone Settings" [isOpen]="zoneSettingsOpenState" (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','zoneSettings'])"
        *ngIf="(ccuOrZone.type == 'zone') && (ccuOrZone.actionUsed == 'click')">
        <puc-widget title=""
          *ngIf="(ccuOrZone.type == 'zone') && (ccuOrZone.actionUsed == 'click')">
          <div class="zone-settings">
            <div class="zone-settings__section" *ngIf="!isModbusProfile(profiles[profiles.length-1]) && zoneSettings">
              <div *ngIf="profiles[profiles.length-1]" [ngClass]="{'pointerDisable': isVofm}">
                <puc-profiles *ngIf='checkdisplayprofile()' [profileName]='profiles' [profileObj]='profileObj'
                  [sliderDisplayFlag]='displayRangeSlider' (desiredTempsChanged)='desiredTempsChangedHandler($event)'
                  [inputBuildingLimit]='buildingLimit' [inputZoneName]='refs.ccuName' [currentRate]='currentRateInput'
                  [energyReading]='energyReadingInput' [piLoopData]="getPIData(profiles[profiles.length-1].profile)" 
                  [senseData]="getSenseData(profiles[profiles.length-1].profile)" [siteTimeZone]="siteTz"
                  [profileFormattedName]="formatZoneName(profiles[profiles.length-1])" [zoneSettings]="zoneSettings">
                </puc-profiles>
              </div>
              <br />
            </div>
            <div class="zone-settings__section"
              *ngIf="zoneSettings && !isModbusProfile(profiles[profiles.length-1])">
              <ul>
                <li *ngIf="profileCheckFlag">
                  <span>Status : </span> <i class="statusText" [innerHTML]="isDataLoaded(zoneSettings.equipScheduleStatus?.val, true)" aria-hidden="true">
                  </i>
                </li>
                <li *ngIf="!profileCheckFlag && !isProfilePI(profiles[profiles.length-1])">
                  <div class="d-flex">
                    <h3 class="highlight notranslate">{{ formatZoneName(profiles[profiles.length-1]) }}</h3>
                    <div [class.online]="isModuleOnline(profiles[profiles.length-1])" class="status margin-top-1"></div>
                  </div>
                  <span>Status: </span> <i [innerHTML]="energyMeterStatus.val" aria-hidden="true"> </i>
                  <div *ngIf='piLoopstatus' [innerHTML]="piLoopstatus"></div>
                  <div class="label" *ngIf="zoneSettings[profiles[profiles.length-1].profile.name] && zoneSettings[profiles[profiles.length-1].profile.name].lastUpdated">Last Updated: <span class="settings__html value-txt">
                    {{dateFormat(zoneSettings[profiles[profiles.length-1].profile.name].lastUpdated.val)}}
                  </span></div>
                </li>
                <!-- Master Operation mode for VRV profile -->
              <li *ngIf = " this.profileObj.profileType == 'vrv' && this.zoneSettings.masterControllerMode.val == 0">
                <span>Master Operation Mode : </span> <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 0" aria-hidden="true">Off
                </i>
                <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 1" aria-hidden="true">Fan (Ventilation)
                </i>
                <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 2" aria-hidden="true">Heat Only Mode
                </i>
                <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 3" aria-hidden="true">Cool Only Mode
                </i>
                <i class="settings__html"
                  *ngIf = "isDataLoaded(zoneSettings.masterOperationMode?.val) == 4" aria-hidden="true">Auto
                </i>
              </li>
              </ul>
            </div>
            
            <div *ngIf="!showModbusEmr">
              <img class="modbus" src="assets/images/modbus.svg" *ngIf="isModbusProfile(profiles[profiles.length-1])" alt="" >
            </div>
            <div *ngIf="showModbusEmr">
              <img class="modbus" src="assets/images/energymeter.svg" *ngIf="isModbusProfile(profiles[profiles.length-1])" alt="" >
            </div>

            <div class="zone-settings__section" *ngFor="let profile of profiles" [ngClass]="{'pointerDisable': isVofm}">
              <ng-container
              *ngIf="(profileCheckFlag || isModbusProfile(profiles[profiles.length-1])) && profile">
              <div class="d-flex">
                <h3 class="highlight notranslate">{{ formatZoneName(profile) }}</h3>
                <div  *ngIf = "!profile.isChild || !isModbus" [class.online]="isModuleOnline(profile)" class="status margin-top-1"></div>
              </div>
              <puc-device-settings id="heatmap-device-settings" *ngIf="!profile.profile['noPoints'] && zoneSettings" [equipObj]="getEquip(['system', 'equip', 'profile'])" [settings]="profiles.length>1?zoneSettings[profile.profile.name]:zoneSettings"
                  [tags]="profile" [cancelValues]="cancelChanges" [siteTimeZone]="siteTz" [isVofm] = "isVofm"
                  (modeChange)="onSettingsChange('', $event, profiles.length>1?profile.profile.name:'')"></puc-device-settings>

                  <div  *ngIf="profile.profile['noPoints']">
                    <h6 class="display_modbus">None of the parameter is enabled in CCU for display. Please enable in CCU to view.</h6>
                </div>
              </ng-container>
            </div>

            <div class="zone-settings__section" *ngIf="profileCheckFlag && zonePriorityVal && zoneSettings" [ngClass]="{'pointerDisable': isVofm}">
              <h3>Zone Priority</h3>
              <puc-button-group [id]="zonePriorityId" [options]="options.zonePriority" [selectedOption]="zonePriorityVal"
                (optionClick)="onBtnClick($event)">
              </puc-button-group>
            </div>
            <div class="zone-settings__section" *ngIf="profileCheckFlag && zoneSettings" [ngClass]="{'pointerDisable': isVofm}">
              <h3>Schedule</h3>
              <puc-button-group [id]="scheduleTypeId" [options]="showSchedulerOccupancy? options.zoneScheduleRevamp : options.zoneSchedule" [selectedOption]="scheduleTypeVal"
                (optionClick)="onBtnClick($event)">
              </puc-button-group>
              <div *ngIf="profileCheckFlag">
                <ng-container *ngIf="scheduleTypeVal == 1 && showSchedulerOccupancy">
                  <div class="legend-container">
                    <div class="schedule-group-heatMap" *ngIf="enableScheduleGroup">{{setScheduleGroupText()}}</div>
                    <div class="legend-container-right">
                      <div class="inner-container" [ngStyle]="{'padding-top': !item?.button ? '2px': '0px'}"  *ngFor="let item of schedulerOccupancyLegend" style="display: flex;">
                        <ng-container *ngIf="!item?.button">
                          <div class="inner-div legend-color-box" [ngStyle]="{'background-color': item?.color}"></div>
                          <div class="inner-div legend-item">{{item.name}}</div>
                        </ng-container>
                        <ng-container *ngIf="item?.button">
                          <ng-container>
                            <div class="legend-item edit-occupancy" (click)="openSchedulerModal(zoneSettings.zoneSchedule)">EDIT SCHEDULE</div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="zone-schedule-view"  *ngIf="showSchedulerOccupancy && !openedSchedulerEditModal">
                  <ng-container *ngIf="scheduleTypeVal == 1 && !zoneSettings?.zoneSchedule?.id" >
                    <div class="no-buildingSchedule"> Zone Schedule entity does not exist</div>
                  </ng-container>
                  <puc-scheduler-occupany
                    *ngIf="scheduleTypeVal == 1 && zoneSettings?.zoneSchedule?.val"
                      [scheduleId]="zoneSettings?.zoneSchedule?.id"
                      showUserTemp="true"
                      [scheduleInfo]="zoneSettings?.zoneSchedule?.val"
                      hideLegend="true"
                      [buildingScheduleInfo]="zoneSettings?.buildingOccupancy?.val"
                      [buildingLimits]="buildingLimit"
                      [elementId]="'modal-view-occupancy'"
                      [enableScheduleGroup]="enableScheduleGroup"
                      [siteTz]="siteTz"
                      [defaultLimits]="defaultLimits"
                      [scheduleGroup] = "zoneSettings?.zoneSchedule?.scheduleGroup"
                      [unoccupiedSetback]="zoneSettings?.zoneLevelunoccupiedZoneSetback?.val"
                      [buildingHeatingUserLimitMax]="zoneSettings?.buildingHeatingUserLimitMax?.val"
                      [buildingCoolingUserLimitMin]="zoneSettings?.buildingCoolingUserLimitMin?.val"
                      [buildingHeatingUserLimitMin]="zoneSettings?.buildingHeatingUserLimitMin?.val"
                      [buildingCoolingUserLimitMax]="zoneSettings?.buildingCoolingUserLimitMax?.val"
                      [buildingHeatingDeadband]="zoneSettings?.buildingHeatingDeadband?.val"
                      [buildingCoolingDeadband]="zoneSettings?.buildingCoolingDeadband?.val"
                      [buildingUnoccupiedZoneSetback]="zoneSettings?.buildingUnoccupiedZoneSetback?.val"
                      [followBuilding]="zoneSettings?.zoneSchedule?.followBuilding">
                  </puc-scheduler-occupany>
                </div>
                <ng-container *ngIf="!showSchedulerOccupancy">
                  <ng-container *ngIf="scheduleTypeVal == 1 && !zoneSettings?.zoneSchedule?.id" >
                    <div class="no-buildingSchedule"> Zone Schedule entity does not exist</div>
                  </ng-container>
                  <puc-scheduler class="heatmap-fs-schedule" 
                  *ngIf="(scheduleTypeVal == 1 && zoneSettings?.zoneSchedule?.val) || (scheduleTypeVal == 0 && zoneSettings?.buildingSchedule?.val)"
                  refType="roomRef" [sliderDisplayFlag]="displayRangeSlider"
                  [scheduleInfo]="(scheduleTypeVal == 1 ? zoneSettings.zoneSchedule.val : zoneSettings.buildingSchedule.val)" [scheduleId]="zoneSettings?.zoneSchedule?.id"
                  [scheduleType]="scheduleTypeVal" [buildingLimits]="buildingLimit" [sliderInput]="schSliderInputData"
                  [zoneName]="refs.ccuName" [refId]="refs.roomRef" [siteId]="refs.siteRef" [siteTz]="siteTz" (schedulerEmitEvent)="schedulerEventEmit()"  [ccuRef]="refs.ccuRef" 
                  [buildingScheduleInfo]="zoneSettings?.buildingSchedule?.val" [defaultLimits]="defaultLimits" [readonly]="scheduleTypeVal==0" (schedulerEmitEvent)="schedulerEventEmit()" [isVofm] = "isVofm">
                </puc-scheduler>
                </ng-container>
                <puc-apply-named-schedule class="heatmap-fs-schedule"
                  *ngIf="scheduleTypeVal == 2"
                  refType="roomRef"
                  [scheduleType]="scheduleTypeVal" 
                  [buildingLimits]="buildingLimit"
                  [siteTz]="siteTz"
                  [zoneSettings]="zoneSettings"
                  [defaultLimits]="defaultLimits"
                  [orgName]="refs?.siteDetails?.organization"
                  [readonly]="true"
                  [multiModuleScheduleTypeList]="multiModuleScheduleTypeList"
                  [multiModuleProfile]="multiModuleProfile"
                  [buildingSettings]="zoneSettings"
                  [refs]="refs"
                  [roomObj]="roomObj"
                  [fromFacilisight]="true"
                  [newSchedulerOnNamedSchedule]="showSchedulerOccupancy"
                  [buildingToZoneDifferential]="zoneSettings?.buildingToZoneDifferential?.val">
                </puc-apply-named-schedule>
                <puc-special-schedules 
                  [specialSchedulesServerData]="zoneSettings?.zoneSpecialSchedule"
                  [siteRef]="refs.siteRef"
                  [isZoneVac]="true"
                  [siteTimeZone] = "siteTz"
                  [sliderDisplayFlag]="displayRangeSlider"
                  [roomRef]="refs.roomRef"
                  [ccuRef]="refs.ccuRef"
                  [sliderInput]="schSliderInputData"
                  [buildingLimits]="buildingLimit"
                  [defaultLimits]="defaultLimits"
                  [zoneName]="refs.ccuName"
                  [buildingOrZonename]="refs.zoneName"
                  (specialSchedulerEmitEvent)="schedulerEventEmit()"
                  [refId]="refs.roomRef"
                  [unoccupiedSetback]="zoneSettings?.zoneLevelunoccupiedZoneSetback?.val"
                  [buildingToZoneDifferential]="zoneSettings?.buildingToZoneDifferential"
                  [showRevampedSpecialScheduler]="showSchedulerOccupancy"  [isVofm] = "isVofm">
                </puc-special-schedules>
              </div>
            </div>
            <ng-container *ngIf="!vacationSkeletonLoader" [ngClass]="{'pointerDisable': isVofm}">
                <puc-vacations *ngIf="profileCheckFlag" [vacationsServerData]="zoneVacations" [isVofm] = "isVofm"
                [roomRef]="refs.roomRef" [ccuRef]="refs.ccuRef" [siteRef]="refs.siteRef" [siteTimeZone]="siteTz" [isZoneVac]=true>
              </puc-vacations>
            </ng-container>
            <div id="skeleton-vacation" *ngIf="vacationSkeletonLoader" >
                <div class="skeleton-vacation-value"  *ngFor="let repeat of [0,1,2,3,4]">
                  <div class="skeleton-vacation-width"   *ngFor="let repeat of [0,1,2,3,4]">
                      <ngx-skeleton-loader   ></ngx-skeleton-loader>
                  </div>
                </div>
            </div>
          </div>
        </puc-widget>
    </puc-accordion>

      <ng-container *ngIf="checkCertificationAccess('zoneReconfiguration')">
        <puc-accordion blockTitle="Zone-Reconfiguration" [titleStyle]="accordionHeaderStyle.firstLevel"
          (accordianState)="setUserPreferencesData($event,['zoneHeatmapPreferences','zoneReconfiguration'])"
          [isOpen]="zoneReconfigurationOpenState"
          *ngIf="(ccuOrZone.type == 'zone') && (ccuOrZone.actionUsed == 'click') && zoneSettings">
          <!-- Reconfiguration -->
          <ng-container>
              <puc-terminal-profile *ngIf= "!isModbusProfile(profiles[profiles.length-1])" [profiles]="profiles" [zoneSettings]="zoneSettings"
                [profileObj]="profileObj"></puc-terminal-profile>
          </ng-container>
        </puc-accordion>
      </ng-container>

      <puc-accordion blockTitle="Remote Access" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','remoteAccess'])" [isOpen]="remoteAccessOpenState"
        *ngIf="showRemoteAccess && checkCertificationAccess('remote-ccu-access') && ccuOrZone.actionUsed === 'click'">
        <puc-remote-access-portal class="rmtContainer" [siteId]="refs.siteRef" [ccuId]="refs.ccuRef" appContext="zone-equip" [targetEntityId]="refs.equipRef"></puc-remote-access-portal>
      </puc-accordion>
    </ng-container>

  </div>

  <div *ngIf="!refs.roomRef">
    <div class="modal__noZonePaired">
      <img src="../../../../../assets/images/NoDevicePaired.svg" alt="">
      <div>
        No Device Currently Paired
      </div>
      <div>
        Please go to the Floor Layout in the CCU to pair a new device
      </div>
    </div>
  </div>
  <div class="arrow"></div>
</puc-modal>


<!-- System Profile-->
<puc-modal *ngIf="ccuOrZone.type =='ccu' || ccuHoverCheckFlag" footerActive="1" [title]="" class="ccu-modal-wrapper" 
  [ngStyle]="siteNameModalStyleClickedFlag ? siteNameModalStyleOnClicked : siteNameModalStyle"
  (mouseleave)="onPopEvent($event)" id="{{refs.ahuRef}}" [ngClass]="{'ccu-modal-wrapper-hover': (ccuOrZone.type == 'ccu' && ccuOrZone.actionUsed == 'hover')}">
  <div class="modal-header">
    <ul>
      <li class="entity-name" [class.w-30]="!siteNameModalStyleClickedFlag">
        <h3 class="text-truncate notranslate"><span class="modal-title" [matTooltipClass]="['common-style-tooltip']" matTooltip="{{ refs.ccuName }}">{{ refs.ccuName }}</span></h3>
    </li>
    <li [class.w-100]="!siteNameModalStyleClickedFlag" class="version-ctnr text-truncate notranslate" [matTooltipClass]="['common-style-tooltip']" [matTooltip]="versionText">
      <span class="bold-text">CCU: </span>
      <span *ngIf="version" class="version-text-append">v</span>{{version || 'NA'}} | 
      <span class="bold-text">CM: </span>
      <span *ngIf="cmVersion" class="version-text-append">v</span>{{cmVersion || 'NA'}} |
      <span class="bold-text">Home: </span>
      <span *ngIf="homeAppVersion" class="version-text-append">v</span>{{homeAppVersion || 'NA'}} |
      <span class="bold-text">Remote: </span>
      <span *ngIf="remoteAccessAppVersion" class="version-text-append">v</span>{{remoteAccessAppVersion || 'NA'}} |
      <span class="bold-text">BAC: </span>
      <span *ngIf="backnetAppVersion" class="version-text-append">v</span>{{backnetAppVersion || 'N.A'}}
    </li>
      <li class="date-picker-li" *ngIf="(ccuOrZone.actionUsed == 'click' ) || modal.system.fixed">
        <span [ngStyle]="{'font-size': '9pt'}">
          Performance for
        </span>
        <span class="notranslate">
          <input type="text" class="system-date-picker" ngxDaterangepickerMd [showCancel]="true" readonly
            [linkedCalendars]="true" [drops]="'down'" [opens]="'right'" [ranges]="ranges" [alwaysShowCalendars]="true"
            [locale]="{ applyLabel: 'ok', format: 'DD MMM YY', separator: ' to '}" [showRangeLabelOnInput]="false"
            [closeOnAutoApply]="true" [autoApply]="true" [(ngModel)]="selected" (change)="setDateRange($event)"
            [class.disabled-download]="!(checkWidgetDataLoaded())" />
        </span>
      </li>
      <li class="download" *ngIf="siteNameModalStyleClickedFlag" [class.disabled-download]="!(checkWidgetDataLoaded() && enableDownLoad)">
        <span *ngIf="!isDownloadExcelPending" (click)="exportExcel('CCU')" class="fas fa-download download-icon" id="exportZoneData" aria-hidden="true"></span>
        <span *ngIf="isDownloadExcelPending" class="fas fa-spinner fa-spin spin-icon" id="exportZoneData" aria-hidden="true"></span>
        <span class="downloading-text" *ngIf="isDownloadExcelPending">Downloading...</span>
      </li>
    </ul>

    <puc-graph-xaxis *ngIf="xCords.length" [xCords]="xCords"></puc-graph-xaxis>
  </div>
  <div>

    <ng-container *ngIf="showSystemEquipGraphics && ccuOrZone.actionUsed">
      <puc-accordion  *ngIf="showEquipGraphics" [disableTranslation]="true" [blockTitle]="'Graphic Profile - '+equipGraphicsProfiles" [isEquipGraphics]="true" [isOpen]="systemLevelEquipGraphics"  (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','systemEquipGraphics'])" (displayTimeToggle)="updateTimeToggle($event)" [enableLastUpdatedTime]="showlastUpdatedToggleEnabled" [enableViewAssets]="enableViewAssetsSystemProfile" [taggedFilesList]="taggedFilesListSystemProfile" [siteRef]="refs.siteRef" [showAssetButton]="fileUpload" [popupType]="ccuOrZone.actionUsed">
        <puc-system-equip-graphics class="equip-graphic-container" *ngIf="equipGraphicSystemProfile && equipGraphicData" [hoverdOrClickEventFalg]="hoverdOrClickEventFalg" [tempPreference] ='userTempPreference' [equipGraphicData]="equipGraphicData" [ccuRef]="refs.ccuRef" [siteRef]="refs.siteRef" [showLastUpdated]="showlastUpdatedToggleEnabled" [systemReconfigProfileName]='equipGraphicSystemProfile '></puc-system-equip-graphics>
        <puc-asset-data-table [ahuRef]="refs.ahuRef" [isSystem]="true" [equipGraphicData]="equipGraphicData"
          [hoverdOrClickEventFalg]="hoverdOrClickEventFalg">
        </puc-asset-data-table>
      </puc-accordion>

      <puc-accordion *ngIf="!showEquipGraphics && equipGraphicSystemProfile" [blockTitle]="equipGraphicsProfiles"
        [isOpen]="systemLevelEquipGraphics" [disableTranslation]="true"
        [siteRef]="refs.siteRef"
        (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','systemEquipGraphics'])"
        [enableViewAssets]="enableViewAssetsSystemProfile" [taggedFilesList]="taggedFilesListSystemProfile" [showAssetButton]="fileUpload">
        <puc-asset-data-table [noSystemEquipGraphic]="true" [ahuRef]="refs.ahuRef" [isSystem]="true">
        </puc-asset-data-table>
      </puc-accordion>

    </ng-container>


    <puc-accordion blockTitle="Predefined Widgets" [isOpen]="CCUPreDefinedWidgetsOpenState" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','preDefinedWidgets'])">
      <ng-container *ngFor="let widget of systemWidgets">
        <puc-accordion [blockTitle]="widget.name" [graphId]="widget.id+refs.ahuRef"
          [titleStyle]="accordionHeaderStyle.secondLevel" isOpen="true">
          <puc-widget>
            <puc-graph [graphWidth]="graphWidth" [graphData]="widget" [graphId]="widget.id+refs.ahuRef" [linearData]=""
              (xAxisCords)="setXAxisCords($event)" [leftPos]="modal.system.left" [moduleIdentifier]=""
              [moduleRef]="refs.ahuRef">
            </puc-graph>
          </puc-widget>
        </puc-accordion>
      </ng-container>
    </puc-accordion>
    <puc-accordion *ngIf="ccuOrZone.actionUsed == 'click'" blockTitle="Alerts" [isOpen]="CCUAlertsAccordionOpenState" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','alertsAccordion'])">
      <ng-container>
        <div *ngIf="refs.siteRef && CCUAlertsAccordionOpenState" class="table-container d-flex flex-column">
          <puc-alerts [siteRefs]="[refs.siteRef]" [excludeChildAlerts]="true" [ccuIds]="[refs.ccuId]" [showAlertType]="true" [equipGraphicsAlertAccordion]="true" [allAlerts]="true" [autoRefreshSubject]="autoRefreshSubject" [allSiteSelected]="false"></puc-alerts>
       </div>
      </ng-container>
    </puc-accordion>
    <ng-container *ngIf="ccuOrZone.type === 'ccu'">
      <ng-container *ngIf="!checkCertificationAccess('customWidgets') && !checkCertificationAccess('customGlobalWidgets');">
        <puc-custom-widgets-view [customWidgets] = customWidgets [scope] = ccuOrZone?.type [actionUsed] = ccuOrZone?.actionUsed (accordionState) ='setUserPreferencesDataForCustomWidget($event)'  [customWidget]="CCUcustomWidgetsOpenState" [customGlobalWidget]="CCUcustomGlobalWidgetsOpenState"
        [heatmapConfigId] = globalDashboardObject?.id [customDateRange] = customDateRange  [isFacilisight]=true (dataRecievedEvt)="chartDataReceivedView($event)"></puc-custom-widgets-view>
      </ng-container>
         <ng-container *ngIf="checkCertificationAccess('customWidgets');">
          <puc-custom-widgets (onVisBuilderClosed)="onVisBuilderClosed();" (chartDataReceived)="chartDataReceived($event)"
            (onAddScopedAnalytics)="onAddScopedAnalytics($event)" [taglist] = tagList [scopedData]="scopedData"
            [showInlineVisBuilder]="showInlineVisBuilder" [editAnalytics]=editAnalytics 
            (updatePosition)="updatePosition($event)" 
            (setUserPreferenceData)="setUserPreferencesData($event,['CCUHeatmapPreferences','customWidgets'])" 
            [blockTitle]="'Custom Widgets'" 
            [openState]="CCUcustomWidgetsOpenState" 
            [customWidgets]="customWidgets.local" [serverDeleteInProgress]="serverDeleteInProgress" 
            [selectedWidgetForDeletionWidgetId]="selectedWidgetForDeletion?.widgetId" 
            [widgetDashboardObjectId]="globalDashboardObject?.id"
            [customDateRange] = "customDateRange"
            [selectedScopedEntityIndex]="selectedScopedEntityIndex"
            [customWidgetAction] ="'create'"
            [ccuOrZone] = "'ccu'"
            [makeLocalWidgetsDataCall]="makeLocalWidgetsDataCall"
            (editWidgetEvt)="editWidgetEvt($event);"
            (deleteWidget)="deleteWidget($event);">
          </puc-custom-widgets>
         </ng-container>
         <ng-container *ngIf="checkCertificationAccess('customGlobalWidgets');">
          <puc-custom-global-widgets [customDateRange]=customDateRange [actionUsed]="ccuOrZone?.actionUsed"
            (setUserPreferenceData)="setUserPreferencesData($event,['CCUHeatmapPreferences','customGlobalWidgets'])"
            [widgetDashboardObjectId]="globalDashboardObject?.id" [customWidgets]="customWidgets?.global"
            [hiddenAccordions]="hiddenAccordions" [blockTitle]="'Custom Global Widgets'"
            [openState]="CCUcustomGlobalWidgetsOpenState" [accordionHeaderStyle]="accordionHeaderStyle?.firstLevel"
            [editAnalytics]=editAnalytics (chartDataReceived)="chartDataReceived($event)"
            (accordionHiddenEvt)="accordionHiddenEvt($event)"></puc-custom-global-widgets>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="ccuOrZone.type == 'ccu'">
      <puc-analytics-layout [entitiesList]="entitiesList" [graphWidth]="graphWidth" [open]="ccuAnalyticsOpenState" (accordionStateChange)="setUserPreferencesData($event,['CCUHeatmapPreferences','analytics'])" [siteRef]="refs.siteRef" [buildingObj]="buildings" [analyticsFrom]="ccuOrZone.type" [siteName]="siteName"  [ccuRef]="refs.ccuRef" [roomRef]="refs.roomRef" [fromPartner]="isPartner" [isReadOnly]="isValidCertificationLevel"></puc-analytics-layout>
    </ng-container>
    <ng-container *ngIf="loadSummaryView && ccuOrZone.actionUsed == 'click' && ccuOrZone.type == 'ccu'">
      <puc-point-summary-layout [open]="ccuPointSummaryOpenState" (accordionStateChange)="setUserPreferencesData($event,['CCUHeatmapPreferences','pointSummary'])" [buildingObj]="buildings" [siteRef]="refs.siteRef" [ccuOrZone]="ccuOrZone.type" [siteName]="siteName" [ccuRef]="refs.ccuRef" [roomRef]="refs.roomRef" [fromPartner]="isPartner" [isReadOnly]="isValidCertificationLevel"></puc-point-summary-layout>
    </ng-container>

     <puc-accordion  *ngIf="checkCertificationAccess('tunersOta') && (ccuOrZone.type == 'ccu') && (ccuOrZone.actionUsed == 'click') " blockTitle="System Tuners" class="system-tuners-accordian" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','systemTuners'])" 
     [isOpen]="systemTunersOpenState">
     <ng-container class="postion-container" *ngIf="tuners?.length">
            <puc-command class="m-12-a" [siteInfo]="this.refs" [level]="'system'" [showCcuLogLevel]="true"></puc-command>
            <puc-tuner-list [notesList] = "filteredNotesList" [hasGroup]="true" [level]="'system'" (isTunerUpdated)="onTunerChange($event)"
            [siteInfo]="refs" [data]="tuners"></puc-tuner-list>
        </ng-container>
      </puc-accordion>


    <puc-accordion blockTitle="CCU Settings" [isOpen]="CCUSettingsOpenState" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','CCUSettings'])"
      *ngIf="(ccuOrZone.type == 'ccu') && (ccuOrZone.actionUsed == 'click')">
      <puc-widget>
        <div class="fs-ccu-settings-wrapper">
          <H1 *ngIf="!(true); else ccuSettings">
            Loading ....</H1>
          <ng-template #ccuSettings>
            <div class="fs-ccu-settings-wrapper__content">
              <div class="fs-ccu-settings-wrapper__content--oaoArc align-item" *ngIf="OaoShow" [ngClass]="{'pointerDisable': isVofm}">
                <div>
                  <img title="bisect" src="../../../../../assets/images/arcBisect.svg" alt="">
                </div>
                <ui-oaoArc [options]="oaoArcOptions" [(value)]="oaoArcValue" style="display: block;
                                          margin-left: auto;
                                          margin-right: auto;text-align: center;width: 138%">
                </ui-oaoArc>
                <div class="fs-ccu-settings-wrapper__content--oaoArc--limits">
                  <div>0 PPM</div>
                  <div>2,000 PPM</div>
                </div>
                
                <div class="d-flex oao-text">
                  <div>OAO</div>
                  <div [class.online]="isOAOOnline()" class="status"></div>
                </div>
              </div>
              <ul>
                <!-- The following block is only excuted for default systyem profile-->
                <li *ngIf="onSystemMode">
                  <span>CONDITIONING</span>
                  <label [ngClass]="{'pointerDisable': isVofm}">
                    <input type="radio" [class.checked]="modeList[0] == 'OFF'" name="condition" [value]="modeList[0]"
                      (click)="onSystemModeChange($event, modeList[0])" [ngModel]="modeList[0]" [checked]="true"
                      id="{{'radio-'+ modeList[0].toLowerCase()}}" disabled />
                    <span class="radiobtn"></span>
                    {{ modeList[0] }}
                  </label>
                </li>
                <!-- The following block is  excuted for  all the system profiles based on the reconfig selection condition mode will change -->
              
                <li *ngIf="ccuSetting['systemMode']?.hasOwnProperty('val')" [ngClass]="{'pointerDisable': isVofm}">
                  <span>CONDITIONING</span>
                  <ng-container *ngFor="let mode of modeList;let i=index">
                    <label *ngIf="isShowCMmode(i)">
                    <input type="radio" [class.checked]="modeList[ccuSetting.systemMode.val] == mode" name="condition" [value]="mode" (click)="onSystemModeChange($event, mode)"
                         [checked]="modeList[ccuSetting.systemMode.val] == mode"
                        id="{{'radio-'+ mode.toLowerCase()}}" />
                    <span class="radiobtn"></span>
                    {{ mode }}
                    </label>
                </ng-container>
                </li>

                <li *ngIf="ccuSetting.occupancyStatus">
                  <span>OCCUPANCY STATUS</span>
                  <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus">
                    <li [innerHTML]="isDataLoaded(ccuSetting.occupancyStatus.val, true)"></li>
                  </ul>
                </li>

                <li *ngIf="ccuSetting.equipStatus">
                  <span>EQUIPMENT STATUS</span>
                  <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus">
                    <li [innerHTML]="ccuSetting.equipStatus.val"></li>
                  </ul>
                </li>
                <li *ngIf="ccuSetting && ccuSetting['cloudLastUpdated']">
                  <span>LAST UPDATED</span>
                  <ul class="systemoperations-wrapper__content--move-right occupancyStatus">
                      <li [innerHTML]="dateFormat(ccuSetting['cloudLastUpdated'].val)"></li>
                  </ul>
              </li>

              <li *ngIf="ccuSetting.demandResponseActivation">
                <ul>
                  <span>
                    Demand Response Active
                  </span>
                  <li>
                    <mat-slide-toggle id="demandResponseActivation" #demandResponseActivation
                      [(ngModel)]="ccuSetting.demandResponseActivation.val"
                      (change)="onToggleBtnChange($event, 'demandResponseActivation')"></mat-slide-toggle>
                  </li>
                </ul>
              </li>
                <li *ngIf="OaoShow && ccuSetting && ccuSetting['oaoLastUpdated']">
                  <span>OAO LAST UPDATED</span>
                  <ul class="systemoperations-wrapper__content--move-right occupancyStatus">
                      <li [innerHTML]="dateFormat(ccuSetting['oaoLastUpdated'].val)"></li>
                  </ul>
                </li>

                <!-- Section is only for DAB and VAV External AHU-->
                <ul class="dab_external_ahu" *ngIf="(equipGraphicSystemProfile == 'vavExternalAHUController') || (equipGraphicSystemProfile == 'dabExternalAHUController')">
                  <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 0">
                    <div class="label">Supply Airflow Temperature:</div>
                    <div class="pointdata">Current:</div>
                    <div class="value" *ngIf="ccuSetting?.supplyAirflowTempCurrent?.hasOwnProperty('val')">
                      {{ccuSetting?.supplyAirflowTempCurrent?.val ? ccuSetting?.supplyAirflowTempCurrent?.val : '-'}}
                    </div>
                    <div class="pointdata">Setpoint:</div>
                    <div class="value" *ngIf="ccuSetting?.supplyAirflowTempSetpoint?.hasOwnProperty('val')">
                      {{ccuSetting?.supplyAirflowTempSetpoint?.val ? ccuSetting?.supplyAirflowTempSetpoint?.val : '-'}}
                    </div>
                  </li>


                  <li class="dab_external_ahu_point">
                    <div class="label">Duct Static Pressure:</div>
                    <div class="pointdata">Current:</div>
                    <div class="value" *ngIf="ccuSetting?.ductStaticPressureCurrent?.hasOwnProperty('val')">
                      {{ccuSetting?.ductStaticPressureCurrent?.val ? ccuSetting?.ductStaticPressureCurrent?.val : '-'}}
                    </div>
                
                    <div class="pointdata">Setpoint:</div>
                    <div class="value" *ngIf="ccuSetting?.ductStaticPressureSetPoint?.hasOwnProperty('val')">
                      {{ccuSetting?.ductStaticPressureSetPoint?.val ? ccuSetting?.ductStaticPressureSetPoint?.val : '-'}}
                    </div>
                  </li>
                  
                  <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 1">
                    <div class="label">Supply Airflow Temperature:</div>
                    <div class="pointdata">Current:</div>
                    <div class="value" *ngIf="ccuSetting?.supplyAirflowTempCurrent?.hasOwnProperty('val')">
                      {{ccuSetting?.supplyAirflowTempCurrent?.val?ccuSetting?.supplyAirflowTempCurrent?.val : '-'}}
                    </div>
                    <div class="dav_damper_label">Operating Mode:</div>

                    <div class="cooling_set_point_value" *ngIf="ccuSetting?.operatingMode?.hasOwnProperty('val')">
                      {{ 
                        ccuSetting?.operatingMode?.val === 0 ? 'Off' :
                        ccuSetting?.operatingMode?.val === 1 ? 'Cooling' :
                        ccuSetting?.operatingMode?.val === 2 ? 'Heating' :
                        ccuSetting?.operatingMode?.val === 3 ? 'Temp Dead' :
                        '-'
                      }}
                    </div>
              
                  </li>
                  <li  class="dab_external_ahu_point" *ngIf="ccuSetting?.dualSetpointControlEnable?.val == 1">
                    <div class="label"></div>
                    <div  class="dav_damper_label">Heating Setpoint: </div>
                    <div  class="cooling_set_point_value">{{ccuSetting?.airTempHeatingSp?.val ? ccuSetting?.airTempHeatingSp?.val : '-'}}</div>


                    <div   class="dav_damper_label">Cooling Setpoint:</div>
                    <div class="value">{{ccuSetting?.airTempCoolingSp?.val ? ccuSetting?.airTempCoolingSp?.val : '-'}}</div>
                  </li>

                  <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dcvDamper?.val">
                    <div class="dav_damper_label">DCV Damper:</div>
                    <div class="dav_damper_pointdata"> {{ccuSetting?.dcvDamper?.val ? ccuSetting?.dcvDamper?.val : '-'}} </div>
                  </li>
                </ul>
              <!--
                  VAV DAB Advanced AHU

                  1. Duct Static Pressure (DSP) based Fan Control:
                    This refers to controlling the fan based on the static pressure within the duct system.
                    It ensures the system operates efficiently by adjusting fan speed according to the duct's pressure.

                  2. Supply Air Temperature Control:
                    This involves controlling the temperature of the supplied air. However, there's a dynamic aspect to it 
                    based on reconfiguration selection.

                    The following parameters need to be checked dynamically for DSP and Supply Air Temperature Control:
                      - If DSP1 is selected: Use supplyAirTemperature1, supplyAirTemperature2, supplyAirTemperature3.
                      - If Average Pressure is selected: Use avgPressure.
                      - If Minimum Pressure is selected: Use minPressure.
                      - If Maximum Pressure is selected: Use maxPressure.

                    Similarly, with Duct Static Pressure point:
                      - Based on the user intent, dynamically adjust supply air temperature based on specific configurations 
                        that provide values to be displayed.
              -->
              <ul class="dab_external_ahu" *ngIf="checkForSystemAdvanceAhuProfile()">
                <!-- Duct Static Pressue-->
                <li class="dab_external_ahu_point" *ngIf="ccuSetting?.hasOwnProperty('pressureBasedFanControl')">
                  <div class="label">Duct Static Pressure:</div>
                  <div class="pointdata">Current:</div>
                  <div class="value" *ngIf="ccuSetting?.pressureBasedFanControl?.hasOwnProperty('val')">
                    {{ getDischargeAirflowTempValue(ccuSetting) }}
                  </div>
                
                  <div class="pointdata" *ngIf="ccuSetting?.hasOwnProperty('ductStaticPressureSetPoint')">Setpoint:</div>
                  <div class="value" *ngIf="ccuSetting?.ductStaticPressureSetPoint?.hasOwnProperty('val')">
                    {{ccuSetting?.ductStaticPressureSetPoint?.val ?? '-'}}
                  </div>
                </li>
                <!-- Supply Airflow Temperature-->
                <li class="dab_external_ahu_point"
                  *ngIf="shouldDisplaySupplyAirTemp()">
                  <div class="label">Supply Airflow Temperature:</div>
                  <div class="pointdata">Current:</div>
                  <div class="value">
                    {{getSupplyAirflowTempValue(ccuSetting)}}
                  </div>
                  <div class="dav_damper_label" *ngIf="ccuSetting?.hasOwnProperty('advancedAhuOperatingMode')">Operating Mode:</div>
              
                  <div class="cooling_set_point_value" *ngIf="ccuSetting?.advancedAhuOperatingMode?.hasOwnProperty('val')">
                    {{
                    getOperatingMode(ccuSetting)
                    }}
                  </div>
                </li>
              
                <li class="dab_external_ahu_point"
                  *ngIf="(shouldDisplaySupplyAirTemp() && (ccuSetting.hasOwnProperty('airTempHeatingSp'))) || ( shouldDisplaySupplyAirTemp() && (ccuSetting.hasOwnProperty('airTempCoolingSp')))">
                  <div class="label"></div>
                  <div class="dav_damper_label" *ngIf="ccuSetting.hasOwnProperty('airTempHeatingSp')">Heating Setpoint: </div>
                  <div class="cooling_set_point_value" *ngIf="ccuSetting.hasOwnProperty('airTempHeatingSp')">
                    {{ccuSetting?.airTempHeatingSp?.val ? ccuSetting?.airTempHeatingSp?.val : '-'}}
                  </div>
              
              
                  <div class="dav_damper_label" *ngIf="ccuSetting.hasOwnProperty('airTempCoolingSp')">Cooling Setpoint:</div>
                  <div class="value" *ngIf="ccuSetting.hasOwnProperty('airTempCoolingSp')">{{ccuSetting?.airTempCoolingSp?.val ?
                    ccuSetting?.airTempCoolingSp?.val : '-'}}</div>
                </li>
              
                <li class="dab_external_ahu_point" *ngIf="ccuSetting?.dcvDamperControl?.val">
                  <div class="dav_damper_label">DCV Damper:</div>
                  <div class="dav_damper_pointdata"> {{ccuSetting?.dcvDamperControl?.val ? ccuSetting?.dcvDamperControl?.val : '-'}}
                  </div>
                </li>
              
              </ul>
                <!---->

                <li class="fs-ccu-settings-wrapper--humidity">
                  <ul *ngIf="ccuSetting.minHumidity?.hasOwnProperty('val')" [ngClass]="{'disableAndBlur': isVofm}">
                    <li>Min Inside Humidity:</li>
                    <li>
                      <mat-select #MinHumidity [(ngModel)]="ccuSetting.minHumidity.val"
                        (selectionChange)="onNumberTickerChange($event, 'minHumidity')">
                        <mat-option *ngFor="let number of humdityValues;let i = index" [value]="i">
                          {{number}}%</mat-option>
                      </mat-select>
                    </li>
                  </ul>
                  <ul *ngIf="onSystemMode" [ngClass]="{'disableAndBlur': isVofm}">
                    <li>Min Inside Humidity:</li>
                    <li>
                      <select [(ngModel)]="defaultMinHumidity" disabled>
                        <option *ngFor="let number of humdityValues;let i = index" [value]="i">
                          {{defaultMinHumidity}}%</option>
                      </select>
                    </li>
                  </ul>

                  <ul *ngIf="ccuSetting.maxHumidity?.hasOwnProperty('val')" [ngClass]="{'disableAndBlur': isVofm}">
                    <li>Max Inside Humidity:</li>
                    <li>
                      <mat-select #MaxHumidity [(ngModel)]="ccuSetting.maxHumidity.val"
                        (selectionChange)="onNumberTickerChange($event, 'maxHumidity')">
                        <mat-option *ngFor="let number of humdityValues;let i = index" [value]="i">
                          {{number}}%</mat-option>
                      </mat-select>
                    </li>
                  </ul>
                  <ul *ngIf="onSystemMode" [ngClass]="{'disableAndBlur': isVofm}">
                    <li>Max Inside Humidity:</li>
                    <li>
                      <select [(ngModel)]="defaultMaxHumidity" disabled>
                        <option *ngFor="let number of humdityValues;let i = index" [value]="i">
                          {{defaultMaxHumidity}}%</option>
                      </select>
                    </li>
                  </ul>
                </li>
                <li *ngIf="ccuSetting" [ngClass]="{'pointerEnable': isVofm}">
                  <puc-intrinsic-schedules class="heatmap-fs-schedule"
                    #intrinsicRef
                    [siteTz]="siteTz"
                    [revampedScheduler]="showSchedulerOccupancy"
                    [scheduleData]="intrinsicScheduleData"
                    >
                  </puc-intrinsic-schedules>
                </li>
                <!--Daikin IE-->
                <div *ngIf="ccuSetting && ccuSetting.occStatus?.val" class="marginTop">
                  <li>
                        <span>OCCUPANCY STATUS ON IE GATE WAY</span>
                        <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus">
                            <li [innerHTML]="OccStatus"></li>
                        </ul>
                  </li>
                  <li>
                    <span>GUID DETAILS</span>
                        <ul class="fs-ccu-settings-wrapper__content--move-right occupancyStatus">
                            <li>@{{refs.siteRef}}</li>
                        </ul>
                  </li>
                  </div>
                  <!--Epidemic Mode-->
                <div *ngIf="OaoShow" class="fs-ccu-settings-wrapper__epidemice-mode">
                  <h4>Epidemic Mode Settings</h4>
                  <li *ngIf="ccuSetting.smartPrepurge">
                    <ul>
                      <li>
                        Smart Pre Purge
                      </li>
                      <li>
                        <mat-slide-toggle #smartPrepurge [(ngModel)]="ccuSetting.smartPrepurge.val"
                          (change)="onToggleBtnChange($event, 'smartPrepurge')"></mat-slide-toggle>
                       
                      </li>
                    </ul>
                  </li>
                  <li *ngIf="ccuSetting.smartPostpurge">
                    <ul>
                      <li>
                        Smart Post Purge
                      </li>
                      <li>
                        <mat-slide-toggle #smartPostpurge [(ngModel)]="ccuSetting.smartPostpurge.val"
                        (change)="onToggleBtnChange($event, 'smartPostpurge')"></mat-slide-toggle>
                      </li>
                    </ul>
                  </li>
                  <li *ngIf="ccuSetting.enhancedVentilation">
                    <ul>
                      <li>
                        Enhanced Ventilation
                      </li>
                      <li>
                        <mat-slide-toggle #enhancedVentilation
                            [(ngModel)]="ccuSetting.enhancedVentilation.val"
                            (change)="onToggleBtnChange($event, 'enhancedVentilation')"></mat-slide-toggle>
                      </li>
                    </ul>
                  </li>
                </div>

               

              </ul>
            </div>
          </ng-template>
          <div class="profile-position" *ngIf="showBtu">
            <div class="d-flex">
              <h4 id="btuMeterType" class="sys-lvl-modbus">{{btuMeterType}}</h4>
              <div [class.online]="isOnline('btu')" class="status margin-top-1-2"></div>
            </div>
            <div *ngIf="hideDisplayUIBtuText" class="p-l-10" [ngClass]="{'pointerDisable': isVofm}">
                <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['btu']"
                [settings]="ccuSetting['btu']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
            </div>

            <div class="display-ui-status" *ngIf="!hideDisplayUIBtuText">
                  <span class="m-l-10" >None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View.</span>
            </div>
          </div>

          <div class="profile-position" *ngIf="showEmr">
            <div class="d-flex">
              <h4 id = "emrMeterType" class="sys-lvl-modbus">{{emrMeterType}}</h4>
              <div [class.online]="isOnline('emr')" class="status margin-top-1-2"></div>
            </div>
            <div *ngIf="hideDisplayUIText" class="p-l-10" [ngClass]="{'pointerDisable': isVofm}">
                <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['emr']"
                [settings]="ccuSetting['emr']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
            </div>
            <div  class="display-ui-status" *ngIf="!hideDisplayUIText">
                <span  class="m-l-10">None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View.</span>
            </div>
          </div>


          <div class="profile-position" *ngIf="showModbus">
            <div class="d-flex">
              <h4 class="sys-lvl-modbus">{{modbusType}}</h4>
              <div [class.online]="isOnline('modbus')" class="status margin-top-1-2"></div>
            </div>
            <div *ngIf="hideDisplayUIModbusText" class="p-l-10" [ngClass]="{'pointerDisable': isVofm}">
                <puc-device-settings class="profileName" *ngIf="ccuSetting && ccuSetting['modbus']"
                [settings]="ccuSetting['modbus']" [tags]="btuTagsProfile" [siteTimeZone]="siteTz" [isVofm] = "isVofm"></puc-device-settings>
            </div>

            <div class="display-ui-status" *ngIf="!hideDisplayUIModbusText">
                  <span class="m-l-10" >None Of The Parameter Is Enabled In CCU For Display. Please Enable In CCU To View.</span>
            </div>
          </div>


        </div>
      </puc-widget>
    </puc-accordion>
    <ng-container *ngIf="checkCertificationAccess('systemReconfiguration') && profileReconfigCheck">
      <puc-accordion blockTitle="Reconfiguration" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','reconfiguration'])" [isOpen]="CCUreconfigurationOpenState"
        *ngIf="(ccuOrZone.type == 'ccu') && (ccuOrZone.actionUsed == 'click')">
        <h4 class="notranslate">{{equipGraphicsProfiles}}</h4>
        <puc-system-profiles-reconfiguration *ngIf="equipGraphicSystemProfile && systemReconfigurationSettings" [systemReconfigurationSettings]="systemReconfigurationSettings" [OaoShow]="OaoShow" [systemReconfigProfileName]="equipGraphicsProfiles" [isdomainName]="isdomainName"></puc-system-profiles-reconfiguration>
      </puc-accordion>
    </ng-container>

    <puc-accordion blockTitle="Remote Access" (accordianState)="setUserPreferencesData($event,['CCUHeatmapPreferences','remoteAccess'])" [isOpen]="remoteAccessOpenState"
      *ngIf="showRemoteAccess && checkCertificationAccess('remote-ccu-access') && ccuOrZone.type === 'ccu' && ccuOrZone.actionUsed === 'click'">
      <puc-remote-access-portal class="rmtContainer" [siteId]="refs.siteRef" [ccuId]="refs.ccuId" appContext="system-equip" [targetEntityId]="refs.ahuRef"></puc-remote-access-portal>
    </puc-accordion>
  </div>

</puc-modal>
