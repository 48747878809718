import { HelperServiceFacilisight } from '@/shared/_services/helper.service';
import { AuthenticationService, ConfigurationService, HelperService, UserService } from '@75f/portal-ui-components';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fs-chiller-plant-monitoring',
  templateUrl: './chiller-plant-monitoring.component.html',
  styleUrls: ['./chiller-plant-monitoring.component.scss']
})
export class ChillerPlantMonitoringComponent implements OnInit, OnDestroy {
  urlSafe: SafeResourceUrl;
  baseUrl: string;
  seletedSite;
  isVofm = false;

  constructor(public sanitizer: DomSanitizer,
    private configService: ConfigurationService,
    public userService: UserService,
    @Inject('LOCALSTORAGE') private localStorage: any,
    private authService: AuthenticationService,
    private helperService: HelperService,
    public helperServiceFs: HelperServiceFacilisight
  ) {
    this.baseUrl = this.configService.getConfig('cpmUrl');
    window.addEventListener('DOMContentLoaded', (event) => {
      const ele1 = document.getElementById('loader');
      ele1?.classList.add('bg');
    });
  }

  ngOnInit() {
    this.isVofm = this.authService.isUserViewOnly();
    const ele = document.querySelector('body');
    ele.style.overflowY = 'hidden';
    const token = this.localStorage.getItem('bearer_token');
    const userData = JSON.parse(this.localStorage.getItem('user_data'));
    this.seletedSite = this.localStorage.getItem('SelectedSite');
    let localStorageKey = this.decideLocalStorageKey(this.seletedSite.siteName);
    const lastViewedDashboardId = this.localStorage.getItem(localStorageKey) || '';
    const certificateLevel = this.localStorage.getItem('certificateLevel') || '';
    const userPrefLangauge = this.helperServiceFs.getLanguagePrefToggle() ? this.helperService.getUserPreferredLanguage() : 'en';
    let url = '';
    if (lastViewedDashboardId) {
      url = `${this.baseUrl}?portal=facilisight&token=${token}&userId=${userData.userId}&siteDetail=${this.seletedSite}&lastViewedDashboard=${lastViewedDashboardId}&certificateLevel=${certificateLevel}&preferredLanguage=${userPrefLangauge}`;
    } else {
      url = `${this.baseUrl}?portal=facilisight&token=${token}&userId=${userData.userId}&siteDetail=${this.seletedSite}&certificateLevel=${certificateLevel}&preferredLanguage=${userPrefLangauge}`;
    }
    url += `&isVofm=${this.isVofm}`;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    this.userService.themeToggleSubject.next(true);

    this.userService.themeChangedSubject.subscribe(()=>{
      // pass theme toggle change to CPM
      document.querySelector('iframe')?.contentWindow?.postMessage('themeChanges','*');
    })
  }

  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }

  receiveMessage(event) {
      try {
        const payload = JSON.parse(event.data);
        if (payload?.dashboardId) {
          let localStorageKey = this.decideLocalStorageKey(this.seletedSite.siteName);
          this.localStorage.setItem(localStorageKey, payload?.dashboardId);
        } 
      } catch (err) {
        console.log('Parse Error with message from iFrame');
      }
  }

  decideLocalStorageKey(siteName) {
    let key = 'cpm_last_viewed';
    if (siteName) {
      key = `${siteName}-${key}`;
    }
    return key;
  }

  ngOnDestroy() {
    const ele = document.querySelector('body');
    ele.style.overflowY = 'auto';
    this.userService.themeToggleSubject.next(false);
  }

}
